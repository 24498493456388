@import './variables';

.email-icon {
	width: 1rem;
	height: 1rem;

	path, line {
		fill: white;
		stroke: none;
	}
}
