#delivery {
	display: flex;
	justify-content: center;
	align-items: center;
	width: calc(100vw - (100vw - 100%));
	height: 400px;

	.link {
		position: relative;
		display: block;

		.text {
			position: absolute;
			text-align: center;
			width: 100%;
			margin-top: 0.85rem;
		}

		.logo {
			background-color: white;
			border-radius: 0.5rem;
			width: 200px;
			height: 100px;
			padding: 1rem;
		}

		.logo2 {
			padding-top: 2.5rem;
			min-height: 150px;
		}
	}
}
