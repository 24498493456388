@import '../static/scss/variables';
@import '../static/scss/animations';

.priority-banner {
	position: relative;
	border: 1px solid white;
	border-radius: 0.5rem;
	background-color: white;


	.header {
		background-color: white;
		color: red;
		padding: 0.3rem 1rem;
		font-size: 1.25rem;
		text-align: center;
		line-height: 1rem;
		min-height: 1rem;
		border-radius: 0.5rem;
		max-width: 90%;
		margin: auto;
		font-weight: 600;
		margin-bottom: 0.5rem;

	}

	&.deleting {
		opacity: 0;
		transition: opacity 0.5s ease-in-out;
	}

}
