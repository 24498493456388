$body-font: 'DM Sans', sans-serif;
$heading-font: 'Literata', serif;
$base-font-size: 16px;
$font-color: #666666;
$base-color: #210202;
$gray: rgb(185, 185, 185);
$accent: #9c2532;
$pinterest: #bd081c;
$twitter: #1da1f2;
$instagram: #000;
$tablet: 768px;
$desktop: 1024px;
$phone: 240px;
$desc-color: #505050;
$sub-color: #990000;

$screen-width: calc(100vw - (100vw - 100%));
$screen-height: 100vh;

$color: #fff; // icon color
$blue: #158fef; // background color
$animation: 750ms; // animation speed
$scale: 1; // icon scale 68/68 default

$slogan-width: 20rem;
