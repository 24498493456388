@import '../../static/scss/variables';

.home-address {
	position: fixed;
	z-index: 104;
	top: 0rem;
	right: 0rem;
	text-align: right;
	font-size: 1.1rem;
	padding: 1.5rem;
	color: red;

	@media only screen and (max-width: 1100px) {
		font-size: 0.9rem;
	}

	@media only screen and (max-width: 550px) {
		font-size: 0.7rem;
		padding-right: 1rem;
		max-width: 120px;
	}

	.addr1 {
		font-weight: 600;

		@media only screen and (max-width: 550px) {
			font-size: 0.75rem;
		}
	}
	.phone {
		font-weight: 600;
		a {
			color: #a0a0a0;
		}
	}
	.fa-compass {
		color: $pinterest;
		font-size: 0.8rem;
	}
}
