@import './variables';

.js-loading *,
.js-loading *:before,
.js-loading *:after {
  animation-play-state: paused !important;
}

body,
html {
  background-color: black;
  padding: 0;
  margin: 0;
  height: auto;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.no-focus-outline a:focus,
.no-focus-outline button:focus {
  outline: none;
}

body {
  font-family: $body-font;
  font-size: $base-font-size;
  color: $font-color;
  // background-color: $base-color;
  background-image: url('../main-bg.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  overflow-x: hidden;
  width: $screen-width;

  .red {
    background-color: red;
  }

  @media screen and (min-width: 1500px) {
    font-size: $base-font-size*1.4;
  }
}

h2 {
  font-family: $heading-font;
}

// #root {
//   height: $screen-height;
// }

main.wrapper {
  position: relative;
  // display: grid;
  width: $screen-width;
  // grid-template-areas:  "content""visual";
  // grid-template-rows: 61.8034% 38.1966%;
  // text-align: center;

  @media screen and (min-width: $tablet) {
    grid-template-areas: "content visual";
    grid-template-columns: 61.8034% 38.1966%;
    grid-template-rows: 1fr 0fr;
    height: $screen-height;
  }

  @media screen and (min-width: $phone) {
    grid-template-areas: "content visual";
    grid-template-columns: 61.8034% 38.1966%;
    grid-template-rows: 1fr 0fr;
    height: $screen-height;
  }

  @media screen and (min-width: $desktop) {
    grid-template-areas: "content visual";
    grid-template-columns: 61.8034% 38.1966%;
    grid-template-rows: 1fr 0fr;
    height: $screen-height;
    text-align: left;
  }
}

a {
  text-decoration: none;
  color: black;

  &:hover {
    color: $accent;
    cursor: pointer;
  }
}

.content {
  grid-area: content;
  display: grid;
  grid-template-areas: "sign-up""social";
  grid-template-rows: 2fr 1fr;
  padding: 2em;
}

.main-sections {  
  min-height: calc(100vh);

  & > section {
    width: $screen-width;

    & > h1 {
      margin: 0;
    }
  }
}

// div.App {
//   position: relative;
//   // display: grid;
//   width: $screen-width;
//   // grid-template-areas:  "content""visual";
//   // grid-template-rows: 61.8034% 38.1966%;
//   text-align: center;

//   @media screen and (min-width: $tablet) {
//     grid-template-areas: "content visual";
//     grid-template-columns: 61.8034% 38.1966%;
//     grid-template-rows: 1fr 0fr;
//     height: $screen-height;
//   }

//   @media screen and (min-width: $desktop) {
//     grid-template-areas: "content visual";
//     grid-template-columns: 61.8034% 38.1966%;
//     grid-template-rows: 1fr 0fr;
//     height: $screen-height;
//     text-align: left;
//   }
// }

// a {
//   text-decoration: none;
//   color: white;

//   &:hover {
//     color: $accent;
//     cursor: pointer;
//   }
// }

// .content {
//   grid-area: content;
//   display: grid;
//   grid-template-areas: "sign-up""social";
//   grid-template-rows: 2fr 1fr;
//   padding: 2em;
// }

// .main-sections {
//   .main-section {
//     height: auto;
//     width: $screen-width;
//   }

//   #home {
//     height: $screen-height;
//   }
// }

// .App {
//   text-align: center;
// }

// .App-logo {
//   height: 40vmin;
//   pointer-events: none;
// }

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

// .App-link {
//   color: #61dafb;
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }