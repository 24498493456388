.page {
    // padding-top: 3rem;
    &.show {
        opacity: 1;
        transition: opacity 1s ease-in-out;
    }

    &.hide {
        opacity: 0;
        transition: opacity 1s ease-in-out;
    }

    &.pad-top-for-menu {
      padding-top: 7rem;
    }

}

