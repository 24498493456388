@import '../static/scss/variables';
@import '../static/scss/animations';

footer {
    text-align: center;
    padding: 2.5rem;
    background-color: black;

    .footer-content-container {
        margin: auto;
        
        .footer-title {
            font-size: 1rem;
            color: white;
        }
        .company-name,
        .address1,
        .address2,
        .copyright {
            font-size: 0.8rem;
        }

        .copyright {
            margin-top: 0.75rem;
            font-size: 0.7rem;
        }
    }
}