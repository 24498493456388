@import './variables';

.phone-icon {
	width: 1rem;
	height: 1rem;

	svg {
		margin-top: -0.3rem;
		transform: translateX(0.2rem);
	}
	path, line {
		fill: white;
		stroke: none;
	}
}
