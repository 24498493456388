@import '../static/scss/variables';
@import '../static/scss/animations';

.special-hours {
	position: relative;
    background-image: url('../static/xmas-bg.png');
    background-position: -5.5rem -1rem;
    padding: 1rem 2rem !important;
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;

	.header {
		color: white;
		padding: 0.3rem 1rem;
		font-size: 1.25rem;
		text-align: center;
		line-height: 1rem;
		min-height: 1rem;
		border-radius: 0.5rem;
		max-width: 90%;
		margin: auto;
		font-weight: 600;
		margin-bottom: 0.5rem;

	}

	.tabular {

		.row {
			display: grid;
			grid-template-columns:70% 40%;
			margin-bottom: 0.1rem;

			.text-left {
				font-size: 1rem;
				text-align: left;
				color: white;
			}

			.text-right {
				text-align: center;
				border-radius: 0.3rem;
				font-size: 0.8rem;
				display: flex;
				align-items: center;
				justify-content: center;
				color: white;

				&.Open {
					// background-color: green;
					font-weight: 700;
				}
				&.Closed {
					color: #c11616;
					font-weight: 700;
				}
			}
		}
	}
}
