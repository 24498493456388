@import '../static/scss/variables';
@import '../static/scss/animations';
@import '../static/scss/bookblock';
@import '../static/scss/component';

#menu {
    background-color: white;

    .menu {
      padding-top: 1.75rem;

      &.fix {
        padding-top: 1.85rem;
      }

      h2 {
        font-size: 2.2rem;
        text-align: center;
        color: $font-color;
        margin-bottom: 0.3rem;

        @media screen and (max-width: 640px) {
          font-size: 1.8rem;
        }
      }
    }

    .sub-text-group {
      // margin-bottom: 1rem;
      text-align: center;
    }
    .sub-text,
    .sub.extra,
    .sub.description {
        text-align: center;
    }
		.sub.description {
			margin-bottom: 0.4rem;
		}
    .sub.extra {
        padding-bottom: 0 !important;
        font-size: 0.8rem;

        @media screen and (max-width: 640px) {
            font-size: 0.6rem;
        }
    }
    .sub-menu h4 {
      // text-decoration: underline;
      font-size: 1.75rem;
      text-align: center;
      border-bottom: 4px solid rgba(175, 24, 18, 0.7);
      color: $font-color;

      .sub {
          font-size: 0.75rem;
          font-weight: 400;
          font-style: italic;
          margin-bottom: 1rem;
      }

      @media screen and (max-width: 640px) {
        font-size: 1.3rem;
      }
    }

    .sub-text {
      font-size: 0.6rem;

      @media screen and (max-width: 1000px) {
        font-size: 0.8rem;
      }
      @media screen and (min-width: 1500px) {
        font-size: 0.95rem;
      }
      @media screen and (max-width: 550px) {
        font-size: 0.65rem;
      }
    }

    .column-item {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      .column {
        font-size: 0.8rem;
        font-weight: 600;
        width: 6rem;
        text-align: right;
        margin-right: 0.5rem;

        @media screen and (max-width: 550px) {
          width: 4rem;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

		.title-icons {
			width: 100%;
			display: flex;
			justify-content: center;
			margin: 0.7rem 0;

			.title-icon {
				margin: 0 1rem;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;

				img {
					width: 3rem;
				}

				.title-icon-text {
					font-size: 0.7rem;
				}
			}
		}

		.underlined {
			width: 100%;
			height: 4px;
			background-color: rgba(175, 24, 18, 0.7);
			margin-bottom: 2rem;
			margin-top: 1rem;
		}
		.nocat {
			font-size: 0.8rem;
			font-style: italic;
		}

    .menu-item {
      margin-bottom: 0.8rem;
      border-bottom: 1px dashed rgba(204, 204, 204, 0.5);
      min-height: 2rem;
      position: relative;
			display: flex;

      @media screen and (min-width: 1500px) {
        font-size: 1rem;
      }

      .middle {
        margin-bottom: 0.2rem;
				width: 99%;
				padding-left: 0.15rem;
      }

			.name,
			.number,
			.icon {
				display: inline-block;
			}

			.icon {
				transform: translateY(0.2rem);

				img {
					width: 1rem;
				}
			}

			.prices {
				flex: 0 0 2.5rem;
				max-width: 2.5rem;
			}
			.number {
				max-width: 2.2rem;
				flex: 0 0 2.2rem;
				padding-top: 0.15rem;
				font-weight: 600;
				color: #960a0a;

				@media screen and (max-width: 680px) {
					font-size: 0.85rem;
				}
			}

			.name {
				margin-right: 0.3rem;
				font-weight: 600;

				span {
					font-weight: 400;
					margin-left: 1rem;
					font-size: 80%;
				}

				@media screen and (max-width: 1650px) {
					font-size: 1.1rem;
				}

				@media screen and (max-width: 550px) {
					font-size: 1rem;
				}

				@media screen and (max-width: 600px) {
					max-width: 156rem;
				}
			}

			.price,
			.line-price {
				float: right;
				font-style: italic;
				font-size: 0.95rem;
				display: flex;

				&.double {
					display: flex;
				}

				span {
					padding: 0.1rem;
					min-width: 2rem;
					text-align: right;
					width: 6rem;

					@media screen and (max-width: 550px) {
						width: 4rem;
					}
				}

				span.price-multi-column {
					margin-right: 0.5rem;

					&:last-child {
						margin-right: 0;
					}
				}

				span.price2 {}

			}

      .description {
        display: block;
        padding-bottom: 0.3rem;
        font-style: italic;
        color: $desc-color;

        @media screen and (min-width: 1650px) {
          font-size: 0.9rem;
        }
        @media screen and (max-width: 550px) {
          font-size: 0.8rem;
        }
      }

      .subitem-container {
          padding-left: 0.1rem;
      }

      .extraextracharge {
        padding: 0.75rem;
        font-size: 1.1rem;
        text-align: center;
        color: red;
        font-weight: 600;
        font-style: normal;
      }

    }

		.line {
			margin-bottom: 0.1rem;
			border-bottom: 1px dashed rgba(153, 0, 0, 0.3);
			display: flex;

			&:last-child {
				border-bottom: none;
			}
		}

    .line,
    .sub-item {
        font-size: 98%;
        color: $sub-color;
        font-weight: 600;

        @media screen and (max-width: 550px) {
            font-size: 0.8rem;
        }
    }

		.sub-item {
			display: flex;
			justify-content: flex-start;

			.subitem-letter {
				margin-right: 0.2rem;
			}

			.subitem-name {
				max-width: 90%;
			}

			.subitem-price {
				justify-self: flex-end;
				margin-left: auto;
			}
		}

		.line-flex {
			display: flex;
		}
		.line-letter {
			margin-right: 0.2rem;
			align-self: flex-start;
			display: inline-flex;
			min-width: 1rem;
		}
		.line-name {
			display: inline-block;
			width: 90%;
		}
		.line-desc {
			font-size: 85%;
			// margin-left: 0.4rem;
			color: #666666;
			display: block;
		}
    .line-price,
    .subitem-price {
			margin-left: auto;
      font-style: italic;
      color: $base-color;
      font-weight: 400;
    }
		.line-content {
			display: flex;
			align-items: center;

			.line-image {
				margin-right: 2rem;

				img {
					max-width: 40px;
				}
			}

			.line-text-content {
				width: 100%;
			}
		}

		.line {
			&.has-image {
				padding: 1rem 0;

				.line-text-content {
					display: flex;
					flex-direction: column;
				}

				.line-image {

					@media screen and (max-width: 550px) {
						margin-right: 1.5rem;
						margin-left: 0.5rem;

						img {
							max-width: 55px;
						}
					}
				}

				.line-name {
					font-size: 1rem;
				}

				.line-desc {
					margin-left: 0;
				}
				.line-price {
					align-items: center;
				}
				.line-letter {
					align-self: center;
					margin-right: 1rem;
				}
			}
		}

  }

  #menubook {
    width: $screen-width;
    height: 100%;
  }

  .menu-bar {
    top: 7rem;
    left: 0;
    width: $screen-width;
    z-index: 102;
    background-color: #de2020;
    color: #ffffff;
		position: relative;

    &.fix {
      position: fixed;
    }

		.left,
		.right {
			position: absolute;
			font-size: 0.8rem;
			top: 50%;
			transform: translateY(-50%);
			padding: 0.27rem 0.5rem;
		}

		.right {
			right: 0;
			background: #de2020;
			// background: linear-gradient(270deg, #de2020 55%, rgba(87, 13, 13, 0.1));
			svg {
				animation: floatingright 1.5s infinite ease-in-out;
			}
		}

		.left {
			left: 0;
			background: #de2020;
			// background: linear-gradient(90deg, #de2020 55%, rgba(87, 13, 13, 0.1));
			svg {
				animation: floatingleft 1.5s infinite ease-in-out;
			}
		}

    ul {
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: center;

      @media screen and (max-width: 1280px) {
        overflow-x: auto;
        white-space: nowrap;
        max-width: calc(100vw - (100vw - 100%));
        display: block;
				padding-left: 1rem;
				padding-right: 1rem;
      }
    }
    li {
      list-style: none;
      display: inline-block;
      padding: 0 0.9rem;

      @media screen and (max-width: 818px) {
        text-align: center;
        min-width: 7rem;
      }


      button {
        color: white;
        text-transform: uppercase;
        font-size: 0.7rem;
        font-weight: 400;
        letter-spacing: 0.03rem;
        border: none;
        background: none;
        height: 100%;
        line-height: 2;

        &:focus {outline:0;}

        &.active {
          border-bottom: 5px solid white;
        }

        &:hover {
          cursor: pointer;
        }
        @media screen and (min-width: 1500px) {
          font-size: 0.9rem;
        }
        @media screen and (max-width: 818px) {
          font-size: 0.6;

          &.active {
            border-bottom: 2px solid white;
          }
        }
      }
    }
  }

	.text-red {
		color: red;
	}

  .New {
    padding: 2px;
    font-size: 12px;
    width: 90px;
    color: rgba(175, 24, 18, .7);
    font-weight: 600;
    background-color: transparent !important;
    margin-left: 0;
    transform: translate(-3px, -3px) !important;
  }

  .bottom-pad {
    padding: 6rem;
  }

	.promo {
		position: fixed;
		bottom: 2rem;
		padding: 0.3rem;
		border-radius: 0.5rem;
		background-color: rgba(175, 24, 18, 0.9);
		color: white;
		left: 50%;
		transform: translateX(-50%);
		font-size: 1.2rem;
		text-align: center;
		width: 75%;
		max-width: 800px;
		transition: bottom 0.2s ease-in-out;

		@media screen and (max-width: 550px) {
			width: 80%;
		}

		&.bottomed {
			bottom: 14rem;
		}
	}
