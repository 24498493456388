@import '../static/scss/variables';
@import '../static/scss/animations';

#home {
		.hours-info {
			opacity: 0;
			color: rgba(255, 255, 255, 0.7);
			max-width: $slogan-width;
			min-width: 13rem;
			margin-top: 1.5rem;
			animation: fade 1s ease-in-out 2s 1 forwards;

			@media screen and (max-width: 800px) {
				transform: scale(0.75);
				margin-top: -3rem;
			}

			@media only screen and (max-width: 550px) {
				margin-top: -4rem;
			}

			.bus-hours, .lunch-hours, .special-hours {
				padding: 0.5rem;
				border-radius: 0.3rem;
				background-color: rgba(255, 0, 0, 0.2);

				.hours-list {

					.day-time-row {
						padding: 0;
					}
				}

				.hours-title {
					font-size: 1rem;
				}

				.messages-container {
					font-size: 0.8rem;
					margin-top: 0.3rem;
				}
			}
		}

}
