@import '../static/scss/variables';
@import '../static/scss/animations';

#contact {
  background-color: white;
  // height: 100vh;
	width: calc(100vw - (100vw - 100%));

  @media screen and (max-width: 550px) {
    height: auto;
  }

  .header {
    padding: 2.5rem;

    @media screen and (max-width: 1100px) {
      padding: 1rem;
    }
  }

  h2 {
    font-family: $body-font;
    font-size: 1.85rem;
    margin: 0;
  }

  .home-icon {
    float: right;
    font-size: 2.5rem;

  }

  .contact {
    // height: 100vh;
  }

  .address-message,
  .contact-message {
    font-size: 0.85rem;
  }

	.contact-message-2 {
		color: white;
		font-size: 1rem;
		padding: 0.5rem;
		background-color: rgba(255,0,0,0.3);
		border-radius: 1rem;
		box-shadow: 0 0 10px 1px rgba(0,0,0,0.8);
	}

  .address-message {
  }

  .contact-message {
    display: inline-block;
    text-align: right;
    width: 100%;
  }

  .center-container {
      display: flex;
      justify-content: center;
      align-items: center;
			padding-bottom: 5rem;
  }
  .contact-container {
    // padding: 2rem;
    // display: flex;
    // justify-content: center;
    // height: 40rem;
    // min-height: calc(100vh - 10rem);

    @media screen and (max-width: 1000px) {
      padding: 1rem;
      display: block;
    }

		.phone-email-info {
			padding: 1.5rem;
			text-align: center;
			color: white;
			display: flex;
			justify-content: center;

			.phone-email-info-container {
				display: flex;
				flex-direction: column;

				.phone-info {
					display: flex;
					align-items: center;

					.phone-value {
						color: white;
						font-size: 1.5rem;
						font-weight: 600;
					}

					.phone-icon {
						width: 3rem;
						height: 3rem;
						margin-right: 0.75rem;
					}
				}

				.email-info {
					display: flex;
					justify-content: center;
					align-items: center;

					.email-value {
						color: white;
						font-size: 1.1rem;
					}

					.email-icon {
						width: 3rem;
						height: 3rem;
						margin-right: 0.75rem;
					}
				}
			}


		}
    .gmaps-container,
    .contact-info {
      width: 100%;
      // max-width: 1100px;
      margin: auto;

      @media screen and (max-width: 550px) {
        width: 100%;
        // height: 100vh;
      }
    }

    .gmaps-container {
      // height: 20rem;
      position: relative;
      max-height: 100vh;

			.header {

				h2 {
					text-align: left;
				}
			}

      @media screen and (max-width: 550px) {
        // height: 12rem;
      }

      .address {
        font-weight: 600;
        font-size: 0.85rem;
        top: 6.5rem;
        left: 0;
        position: absolute;
        min-height: 8.5rem;
        width: $screen-width;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 70%, rgba(255, 255, 255, 0) 100%);
        z-index: 5;

        @media screen and (max-width: 1100px) {
          top: 3.75rem;
          margin-top: 0.75rem;
          padding-top: 0.1rem;
        }

        @media screen and (max-width: 550px) {
          top: 4rem;
          margin-top: 0.75rem;
          padding-top: 0.1rem;
          background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 70%, rgba(255, 255, 255, 0) 100%);
        }

        .address-inner {
          padding: 2.5rem;
          @media screen and (max-width: 550px) {
            padding: 1.5rem;
          }
        }

        .officename {
          color: red;
        }
      }

      .gmaps {
        width: 100%;
        height: 100%;

      }
    }
    .contact-info {
      position: relative;
      height: 100%;
      background-color: rgb(106, 11, 11);
      background-image: url('../static/view1.jpg');
      background-size: cover;
      background-position-x: 50%;
      color: white;
      box-shadow: 2px 0 12px 1px #000;

      @media screen and (max-width: 550px) {
        width: 100%;
        // height: 15rem;
      }

      .transparent-container {
        background-color: rgba(0,0,0,0.7);
        height: 100%;
      }

      h2 {
        text-align: right;
      }

      .warning {
        width: 100%;
        text-align: center;
        margin-top: 1rem;

        @media screen and (max-width: 550px) {
          margin: 0.3rem;
        }
      }

      .infos-container {
        // padding: 4.5rem;
        font-size: 1.2rem;
        display: flex;
        text-align: left;
        // padding-top: 0.5rem;

        @media screen and (max-width: 550px) {
          // display: flex;
          padding: 1rem;
          padding-top: 0.1rem;
        }

        .phone-info,
        .email-info {
          width: 100%;

          @media screen and (max-width: 550px) {
            max-height: 2rem;
            margin-top: 0.85rem;
            margin-bottom: 0.85rem;
            justify-content: center;
          }

          &:hover {
            color: white;
          }

          .fa {
            font-size: 2rem;

            @media screen and (max-width: 550px) {
              font-size: 1.5rem;
            }
          }
          .phone-value,
          .email-value {
            font-size: 1.5rem;
            color: white;

            @media screen and (max-width: 550px) {
              font-size: 1rem;
            }
          }

          .email-value {
              font-size: 1.1rem;
          }
        }
      }
    }

  }

}

.marker {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: red;
  position: absolute;
  transform: 'translate(-50%, -50%)';

  .address {
    position: relative;
    background: #690606;
  }
  .address:after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(105, 6, 6, 0);
    border-top-color: #690606;
    border-width: 30px;
    margin-left: -30px;
  }

}

#google-map {
	margin: auto;
	overflow: hidden;
}
