@import '../static/scss/variables';
@import '../static/scss/animations';

header {
  // flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 103;
  width: $screen-width;
  height: 100%;
  max-height: 7rem;
  transition: all 0.4s ease-in-out;

  &.expanded {
    background-color: black;

    .hamburger.is-open {
      margin-top: 0.1rem;
    }

    .header-menu {
      padding: 0;
      position: relative;
      height: 100%;

      @media screen and (max-width: 550px) {
        padding-left: 1rem;
        padding-right: 1rem;
        position: relative;
      }

      .logo {
        position: absolute;
        top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        margin-left: -4.5rem;
        display: block;
        animation: fade 0.5s ease-in-out 0s 1;

				@media screen and (min-width: 1500px) and (max-width: 2000px) {
					transform: translate(-50%, -50%);
				}

				@media screen and (min-width: 921px) and (max-width: 1499.5px) {
				  transform: translate(-20px, -50%);
				}

				@media screen and (max-width: 920px) {
					transform: translate(-120px, -50%);
				}

				@media screen and (max-width: 550px) {
					transform: translate(0, -50%);
				}

        img {
          width: 5rem;
        }
      }

      nav {

				@media screen and (min-width: 921px)and (max-width: 1499.5px) {
					transform: translateX(0px);
				}
				@media screen and (min-width: 551px)and (max-width: 920px) {
					transform: translateX(-100px);
				}

        &.show {
          display: block;

          @media screen and (max-width: 550px) {
              width: $screen-width;
              position: absolute;
              top: 100%;
              left: 0;
							transform: translateX(0);
          }

          ul {
            @media screen and (max-width: 550px) {
              flex-direction: column;
              width: $screen-width;
              position: static;
              transform: initial;

            }
            li {
              @media screen and (max-width: 550px) {
                padding: 1rem;
                margin: 0 !important;
                background-color: black;
              }

            }
          }
        }

        ul {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          li {
            transition: all 0.4s ease-in-out;

            &:nth-child(3) {
              margin-left: 6rem !important;
              border-left-color: transparent;
            }
            a {
							width: 100%;
							display: block;

							// &:hover {
							// 	border: none !important;
							// }
            }
          }
        }
      }

			&.dark.showing-compact-menu {
				nav {
					display: block !important;
					position: absolute;
					top: 100%;
					background-color: black;
					width: calc(100vw - (100vw - 100%));
					min-height: 2rem;

					ul {
						display: flex;
						flex-direction: column;
						list-style-type: none;
						margin: auto;
						padding: 0;

						@media screen and (min-width: $desktop) {
							justify-content: flex-start;
						}

						li {
							padding: 1rem 2rem 1rem 2em;
							text-align: left;
							border-left: none;
							background-color: black;

							&:hover {
								background-color: rgba(255, 0, 0, 0.8);
							}

							&.active {
								a {
									border-left: none;
								}
							}

							a {
								color: white;

								&:hover {
									border-bottom: 2px solid red;
								}
							}

							&:nth-child(3) {
								margin-left: 0 !important;
								border-left-color: transparent;
							}

						}
					}
				}
			}
    }
  }

  .header-menu {
    padding: 0.8rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
		transition: background-color 0.3s ease-in-out;

    @media screen and (max-width: 550px) {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    &.dark {
      background-color: rgb(0,0,0);
    }

    .logo {
      display: none;
		}

    nav {
      text-align:center;
      align-self: center;

      @media screen and (max-width: 550px) {
        display: none;
      }

      ul {
        display: flex;
        list-style-type: none;
        margin: auto;
        padding: 0;
        justify-content: center;
        align-content: center;
				position: static !important;
				left: 0 !important;
				top: 0 !important;
				transform: none !important;

        @media screen and (min-width: $desktop) {
          justify-content: flex-start;
        }

        li {
          padding: 0 1em;
          border-left: 1px solid $gray;
          margin-left: 0 !important;

          &.active {
            a {
              border-bottom: 2px solid red;
            }
          }

          a {
            color: white;
            &:hover {
              border-bottom: 2px solid red;
            }
          }

          &:first-child {
            padding-left: 0;
            border: none;
          }

          &:last-child {
            padding-right: 0;
          }

        }
      }
    }
  }

}


.hamburger {
  padding: 0;
  border: none;
  background-color: transparent;
  transform: scale($scale);
  position: relative;
  display: none;
  //background: $blue;
  -webkit-touch-callout: none;
  user-select: none;
  align-self: flex-start;
  justify-self: flex-start;

  @media screen and (max-width: 550px) {
    display: block;
    margin-right: auto;
    align-self: center;

  }
}
#top, #bottom {
  position: absolute;
  display: block;
  height: 4px;
  width: 36px;
  border-radius: 2px;
  background: red;
}
#top {
  top: 19px;
  left: 14px;
}
#bottom {
  top: 41px;
  left: 14px;
}

path, line {
  stroke: $color;
  stroke-linecap: round;
}


// Starting values
#top, #bottom {
  transform-origin: 34px 2px;
  transform: rotate(0deg); // to prevent pixelatedness
  transition: all $animation * (2/3) cubic-bezier(.8, -1.1, .5, 1.9); // x to = animation
}
#circle {
  stroke-dasharray: 1 100 32 300; // 32 is the hamburger size
  stroke-dashoffset: 101; // setting up a gap to swipe in from the left
  transition: all $animation ease; // - to () animation
}

.hamburger.is-open {
  margin-top: 0.65rem;
}

// End values (animation done by transitions)
.hamburger.is-closed {
  // The circle animation is done by changing two things:
  // - a new 100px gap is swiped in from the left by changing offset
  // - the 'burger' dash is increased from 32px to 190px
  #circle {
    stroke-dasharray: 1 100 190 300;
    stroke-dashoffset: 1;
  }

  // Rotate the top and bottom 'bum' to make a cross, and shift them to
  // the right a little bit
  #top {
    transform: translateX(-4.5px) rotate(-45deg);
  }
  #bottom {
    transform: translateX(-4.5px) rotate(45deg);
  }
}
