@import '../static/scss/variables';
@import '../static/scss/animations';


#about {
  color: #ffffff;
  // height: 100vh;
  width: $screen-width;
  font-size: 1rem;

  .about {
    padding: 2.5rem;
		max-width: 1200px;
		margin: auto;
  }

  .about-title {
    text-align: center;

    h2 {
      margin-top: 0;
    }
  }

  .col2 {
    display: flex;
    width: 100%;

    @media screen and (max-width: 720px) {
      display: block;
      text-align: center;
    }
  }

  .about-left,
  .about-right {
    width: 50%;
    margin: 1rem;

    @media screen and (max-width: 720px) {
      width: 100%;
      margin: 0;
    }
  }

  .about-right {
    @media screen and (max-width: 720px) {
      margin-top: 2rem;
    }
  }
}

.swiper-slide {
  max-width: 100%;
  max-height: 500px;

  img {
    max-height: 500px;
    max-width: 100%;
  }

  @media screen and (max-width: 720px) {
    margin-top: 2rem;
  }
  &.thumb {
    max-width: 100px;
    max-height: 75px;

    img {
      max-height: 75px;
    }
  }
}

.swiper-pagination {
  display: flex;
  justify-content: center;
}
.swiper-pagination-bullet {
  display: inline-block;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid white;
  width: 0.85rem;
  height: 0.85rem;
  cursor: pointer;
  margin-right: 0.4rem;
}
.swiper-pagination-bullet-active {
  background-color: white;
  border: 2px solid white;
  transition: background-color 0.3s ease-in-out;
}
