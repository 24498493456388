@import '../static/scss/variables';
@import '../static/scss/animations';

.show {
	display: block;
}

.hide {
	display: none;
}

.center {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 0;
	left: 0;

	justify-content: center;
	align-items: center;

	@media screen and (max-width: 800px) {
		justify-content: flex-start;
	}

	&.expanded {
		img {
			animation: fade 0.3s ease-in-out 0s 1 forwards;
			animation-direction: reverse;
		}
	}

	.logo-slogan-container {
		display: block;
		flex-direction: row;
		text-align: center;

		width: $slogan-width;
		// margin-top: 5rem;
		// height: 14rem;

		@media screen and (max-width: 800px) {
			transform: scale(0.75);
			transition: transform 0.3s ease-in-out,
			margin-top 0.3s ease-in-out;
			margin-top: 2rem;
		}


		.logo {
			// background-image: url('../../static/genji-logo-fit.png');
			// background-size: cover;
			display: block;
			animation: fade-from-top 1s;
			width: $slogan-width;


			img {
				// max-width: 100%;
				width: $slogan-width;

				@media screen and (min-width: 1000px) {
					width: 16rem;
					margin: auto;
				}

				@media screen and (max-width: 550px) {
					width: 14rem;
					margin: auto;
				}
			}
		}

		h3.slogan {
			color: white;
			font-family: $heading-font;
			font-style: italic;
			// using animation-fill-mode [backwards] because the element
			// is visible/static before animation starts
			animation: fade-from-bottom 1s ease-in-out 0s 1 backwards;
			font-size: 1.4rem;
		}

		.line {
			display: block;
			height: 0.085rem;
			background-color: white;
			width: $slogan-width;
			margin-top: 0.8rem;
			margin-bottom: 0.7rem;
			animation: fade-scale 1.5s ease-in-out 0.5s 1 backwards;
		}

		button.call-to-action {
			cursor: pointer;
			padding: 1rem;
			font-size: 1.2rem;
			color: white;
			background-color: transparent;
			border: 2px solid red;
			min-width: 8rem;
			width: 100%;
			animation: fade 1.5s ease-in-out 1s 1 backwards;
			border-radius: 0.5rem;

			&:hover {
				cursor: pointer;
				background-color: white;
				color: $font-color;
			}
		}

	}

	.priority-banners-container {
		bottom: 1rem;
		position: fixed;
		max-width: 500px;
		min-width: 300px;
		align-self: center;
		justify-self: center;
		animation: fade 1.5s ease-in-out 1s 1 backwards;
	}

}
