.hours-info {
	font-size: 1rem;
	width: 100%;
	padding: 0.5rem;

	@media screen and (max-width: 550px) {
		font-size: 0.9rem;
		// display: flex;
	}

	.fa {
		margin-right: 0.7rem;
	}

	.bus-hours,
	.lunch-hours {

		.hours-title {
			font-size: 1.5rem;
			margin-bottom: 0.5rem;
			font-weight: 100;
			text-align: center;
		}

		@media screen and (max-width: 550px) {
			// width: 50%;
			// margin-right: 1rem;
		}
	}

	.bus-hours {
		margin-bottom: 1rem;
	}

	.hours-title {
		margin-bottom: 0.55rem;
		font-size: 1.1rem;

		@media screen and (max-width: 550px) {
			font-size: 0.9rem;
		}
	}

	.hours-list {
		font-size: 1rem;
		text-align: left;

		@media screen and (max-width: 550px) {
			font-size: 1rem;
		}

		.day-time-row {
			padding: 0.2rem 0.75rem;
			display: flex;
			justify-content: space-between;

			.day {}

			.time {}

		}
	}

	.messages-container {
		font-size: 0.8rem;

		@media screen and (max-width: 550px) {
			font-size: 0.75rem;
		}
	}
}
